import React from 'react'
import PackeryModule from 'react-packery-component'
import Layout from 'components/layout'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Title from 'components/title'
import { graphql } from 'gatsby'
import { trFunction } from 'utils/functions'

const Packery = PackeryModule(React)
const packeryOptions = {
  itemSelector: '.item-grid',
  percentPosition: true,
  gutter: '.gutter-sizer',
  transitionDuration: '0', //'0.4s'
}

const Designer = ({ i, designer }) => {
  let addClass = ''
  if (i > 0) {
    if (i === 1 || i % (1 + 7) === 0) addClass = 'push-right'
    if (i === 2 || i % (2 + 7) === 0) addClass = 'push-left'
  }
  return (
    <div className={`item-grid zoom-img-on-hover ${addClass}`}>
      <LinkWrap to={`/collections/designers/${designer.slug}`}>
        <ImageWrap image={designer.image} aspectRatio={3 / 4} />
      </LinkWrap>
      <h4>
        <LinkWrap
          to={`/collections/designers/${designer.slug}`}
          className="dark"
        >
          {designer.title}
        </LinkWrap>
      </h4>
    </div>
  )
}

const DesignersPage = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  const designers = data.designers.edges

  return (
    <Layout>
      <div id="content">
        <Title
          title={page.title}
          subtitle={tr('COLLECTIONS')}
          subtitleLink="/collections"
        />
        <section className="page-content wrap">
          <div className="wrapper-grid-designers">
            <Packery
              className={'grid-packery grid-designers-list'}
              options={packeryOptions}
            >
              <div className="gutter-sizer" />
              {designers.map((designer, i) => (
                <Designer key={i} i={i} designer={designer.node} />
              ))}
            </Packery>
          </div>
        </section>
      </div>
    </Layout>
  )
}
export default DesignersPage

export const designerPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    designers: allContentfulDesigners(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          id
          title
          slug
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          image {
            ...GatsbyImage
          }
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "designers" }) {
      title
      slug
    }
  }
`
